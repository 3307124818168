import { ContextApi } from '@pancakeswap/localization';
import {
  DropdownMenuItems,
  EarnFillIcon,
  EarnIcon,
  LineGraphIcon,
  LinkExternal,
  MenuItemsType,
  SwapFillIcon,
  SwapIcon
} from '@pancakeswap/uikit';

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Liquidity'),
      href: '/liquidity',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Stats & Graphs'),
      href: '/info/v3',
      icon: LineGraphIcon,
      fillIcon: LineGraphIcon,
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Meet 9x'),
      href: 'https://9x.9mm.pro/#/swap?chainId=8453',
      icon: LinkExternal,
      fillIcon: LinkExternal,
      showItemsOnMobile: false,
      items: []
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
